// CourseSchedule.js
import React from "react";
import {
  Box,
  Typography,
  Link,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper
} from "@mui/material";

const CourseSchedule = () => {
  const courseScheduleData = [

    { grade: "小一预备班（K2)", tuitionSchedule: "星期日sunday 1pm-3pm" },
    { grade: "小一（P1）", tuitionSchedule: "星期六 Saturday 9am-11am" },
    {
      grade: "小二（P2）",
      tuitionSchedule: [
        "星期四 Thursday 5:30pm-7:30pm",
      ],
    },
    { grade: "小三（P3）", tuitionSchedule: [
      "A班（ClassA) 星期五 Friday 3pm-5pm",
      "B班（ClassB) 星期六 Saturday 11am-1pm",
      "C班（ClassC) 星期二 tuesday 5pm-7pm",
    ] },
    {
      grade: "小四（P4））",
      tuitionSchedule: [
        "A班（ClassA) 星期二 Tuesday 7pm-9pm",
        "B班（ClassB) 星期日sunday 9am-11am"
      ],
    },
    {
      grade: "小五（P5）",
      tuitionSchedule: [
        "A班（ClassA) 星期一 Monday 7pm-9pm",
        "B班（ClassB) 星期六 Saturday 3pm-5pm",
      ],
    },
    { grade: "小六（P6）", tuitionSchedule: [
      "A班（ClassA) 星期一 Monday 5pm-7pm",
      "B班（ClassB) 星期五 Friday 5pm-7pm",
      "C班（ClassC) 星期六 Saturday 1pm-3pm"


    ] },
    { grade: "中一（S1）", tuitionSchedule: "星期天 Sunday 11am-1pm" },
    { grade: "中二（S2）", tuitionSchedule: [
      "A班（ClassA) 星期四 Thursday 7:30pm- 9:30pm",
      "B班（ClassB) 星期六 Saturday 3pm-5pm"
    ] },
    { grade: "中三（S3）", tuitionSchedule: "星期五 Friday 7pm-9pm" },
  ];

  return (
    <Box sx={{ padding: "20px", overflowX: "auto" }}>
      <Typography
        variant="h4"
        gutterBottom
        sx={{ textAlign: "center", marginBottom: "20px" }}
      >
        Course Schedule
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 300, textAlign: "center" }}>
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <b>Grade</b>
              </TableCell>
              <TableCell align="center">
                <b>Tuition Schedule</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {courseScheduleData.map((row, index) => (
              <TableRow key={index}>
                <TableCell align="center" style={{ whiteSpace: "nowrap" }}>
                  {row.grade}
                </TableCell>
                <TableCell align="center">
                  {Array.isArray(row.tuitionSchedule) ? (
                    <ul style={{ padding: 0, margin: 0 }}>
                      {row.tuitionSchedule.map((schedule, idx) => (
                        <ul key={idx}>
                          <Typography variant="body2" component="div">
                            {schedule}
                          </Typography>
                        </ul>
                      ))}
                    </ul>
                  ) : (
                    <Typography variant="body2" component="div">
                      {row.tuitionSchedule}
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Download link for the PDF file */}
      <Box sx={{ textAlign: "center", marginTop: "20px" }}>
        <Link href="/course-schedule.pdf" target="_blank" download>
          Download Course Schedule (PDF)
        </Link>
      </Box>
    </Box>
  );
};

export default CourseSchedule;