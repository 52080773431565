// TeacherBio.js
import React from 'react';
import { Box, Avatar, Typography, List, ListItem, ListItemText } from '@mui/material';

const TeacherBio = () => {
  // You can replace the image URL with the teacher's photo
  const teacherPhotoUrl = process.env.PUBLIC_URL + '/images/teacher-photo.png';


  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Avatar src={teacherPhotoUrl} alt="Teacher Photo" sx={{ width: '100px', height: '100px', marginRight: '10px' }} />
      <Box>
        <Typography variant="h6" gutterBottom>
          Mrs Shao Xinmo(邵老师)
        </Typography>
        <List dense>
          <ListItem>
            <ListItemText primary="Subject" secondary="Chinese 华文" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Experience" secondary="19 years experience in education sector since 2003 近20年华文教育经验" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Qualifications" secondary="Further language competency obtained from NTU with 2015 in 2017 南大语言教育专业证书" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Contact" secondary="+65 96873957" />
          </ListItem>
          {/* Add more bullet points here */}
        </List>
      </Box>
    </Box>
  );
};

export default TeacherBio;