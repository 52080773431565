// Header.js
import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import CompanyLogo from "./CompanyLogo";
import CourseSchedule from "./CourseSchedule";
import CourseList from "./CourseList";

const Header = () => {
  const [anchorElCourse, setAnchorElCourse] = useState(null);
  const [anchorElSchedule, setAnchorElSchedule] = useState(null);

  const handleCourseMenuClick = (event) => {
    setAnchorElCourse(event.currentTarget);
  };

  const handleCourseMenuClose = () => {
    setAnchorElCourse(null);
  };

  const handleScheduleMenuClick = (event) => {
    setAnchorElSchedule(event.currentTarget);
  };

  const handleScheduleMenuClose = () => {
    setAnchorElSchedule(null);
  };

  return (
    <AppBar position="static">
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        {/* Tuition center logo */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <CompanyLogo />
          <Typography variant="h6" component="div" sx={{ ml: 1 }}></Typography>
        </div>

        {/* Navigation links */}
        <div>
          <Button
            color="inherit"
            aria-controls="course-list-menu"
            aria-haspopup="true"
            onClick={handleCourseMenuClick}
          >
            Courses
          </Button>
          <Button
            color="inherit"
            aria-controls="course-schedule-menu"
            aria-haspopup="true"
            onClick={handleScheduleMenuClick}
          >
            Course Schedule
          </Button>
          <Menu
            id="course-schedule-menu"
            anchorEl={anchorElSchedule}
            keepMounted
            open={Boolean(anchorElSchedule)}
            onClose={handleScheduleMenuClose}
          >
            <MenuItem onClick={handleScheduleMenuClose}>
              <CourseSchedule />
            </MenuItem>
          </Menu>
          <Menu
            id="course-list-menu"
            anchorEl={anchorElCourse}
            keepMounted
            open={Boolean(anchorElCourse)}
            onClose={handleCourseMenuClose}
          >
            <MenuItem onClick={handleCourseMenuClose}>
              <CourseList />
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
